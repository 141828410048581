import type { ButtonColor } from "@kippie/ui";

/// Maps a (StoryBlok) Hex color to corresponding KippieButton color.
export const mapToKippieButtonColor = (hex: string): ButtonColor => {
	switch (hex) {
		case "#FFFFFF":
			return "white";
		case "#181C1D":
			return "black";
		case "#FFD923":
		default:
			return "yellow";
	}
};
